import React, { useState } from "react";
import App2 from "./App2";
import Login from "./login";
import ApplicationLayout from "./components/application"
import PrivateRoute from "./login/privateRoute";
import AdminLayout from "./components/adminLayout"
import NewHome from "./components/newHome"
import DesignGroups from "./components/application/designGroups"
import Home from "./components/home"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GlobalContext } from "./storage";
import ".././src/App.css"
const authTokens = JSON.parse(localStorage.getItem("authTokens"));

function App() {
  const [globalStore, setGlobalStore] = useState({authTokens : authTokens});
  return (
    <GlobalContext.Provider value={{ globalStore, setGlobalStore }}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/" layout={AdminLayout} component={NewHome} />
          <Route exact path="/login" component={Login}/>
          <PrivateRoute exact path="/applications" layout={AdminLayout} component={ApplicationLayout} />
          <PrivateRoute exact path="/designgroups" layout={AdminLayout} component={DesignGroups} />
          <PrivateRoute exact path="/API" layout={AdminLayout} component={Home} />
        </Switch>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
