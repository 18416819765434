import React, { useState } from 'react';
import { Layout,Avatar } from 'antd';
import { useHistory } from "react-router-dom";
import {LogoutOutlined} from '@ant-design/icons'
import SideMenu from "../sideMenu"
import Logo from "../../assets/images/logo.png";
import LogoCollapse from "../../assets/images/logoCollapse.svg";
import expandMenuIcon from "../../assets/images/expandMenuIcon.svg";
import collapseMenuIcon from "../../assets/images/collapseMenuIcon.svg";
import logoutIcon from "../../assets/icons/logout.svg"

import "antd/dist/antd.css";
import "../../styles/antd.css";
import "../../App.css";
const { Content, Sider } = Layout;


const AdminLayout = (props) => {
    const [collapsed, setCollapsed] = useState(false);

    let history = useHistory();
    const NavigateToMenu = (url) =>{
      history.push(`${url}`)
    }

    const logout = () =>{
      window.location.reload()
      localStorage.clear()
      history.push('/login')
    }

    return (
        <Layout
      style={{
        minHeight: '100vh',
        fontFamily:'Open Sans'
      }}
    >
      <Sider 
      collapsible
      trigger={null}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className={`${collapsed ? "siderCollapseStyles" : "siderStyles"}`}
      >
      <div
          style={{
            height: "50px",
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {!collapsed ? (
            <img
              style={{ cursor: "pointer", width: "90px", marginTop: "5px" }}
              src={Logo}
              alt="Logo"
              onClick={()=>NavigateToMenu("/")}
            />
          ) : (
            <img
              style={{
                cursor: "pointer",
                width: "40px",
                marginTop: "8px",
                height: "30px",
              }}
              src={LogoCollapse}
              alt="Logo"
              onClick={()=>NavigateToMenu("/")}
            />
          )}
        </div>
        <SideMenu />
        <div>
        {collapsed ? (
            <span>
            <span>
              <Avatar style={{
                position: "absolute",
                bottom: "75px",
                left: "10px",
                cursor: "pointer",background:'white'}}
                size={38}><span style={{color:'#202223',fontWeight:'600'}}>AI</span></Avatar>
            </span>
            <span style={{position: "absolute",
                bottom: "41px",
                left: "17px",cursor: "pointer"}}>
            <img onClick={logout} src ={logoutIcon} alt="logout" style={{width:'20px'}} />
            </span>
            <span
              style={{
                position: "absolute",
                bottom: "10px",
                left: "17px",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "20px" }}
                alt="showMenu"
                src={expandMenuIcon}
              />
            </span>
            </span>
          ) : (
            <span>
              <span>
              <Avatar style={{
                position: "absolute",
                bottom: "80px",
                left: "10px",
                cursor: "pointer",background:'white'}}
                size={38}><span style={{color:'#202223',fontWeight:'600'}}>AI</span></Avatar>
                <span style={{
                position: "absolute",
                bottom: "81px",
                left: "32%",
                lineHeight:'15px',
                color:'#ffffff',
                fontSize:'12px'
                }}>
                Avinash Ingole
                <br style={{marginBottom:'0px'}} />
                <span style={{fontSize:'11px',opacity:1}}>System Admin</span>
              </span>
            </span>
            <span onClick={logout} style={{position: "absolute",
                bottom: "45px",
                left: "11px",cursor: "pointer"}}>
            <img onClick={logout} src ={logoutIcon} alt="logout" style={{width:'20px'}} />
            <span style={{
                position: "absolute",
                // bottom: "81px",
                left: "32px",
                lineHeight:'25px',
                color:'#ffffff',
                fontSize:'12px'
                }}>
                Logout
              </span>
            </span>
            </span>
          )}
        </div>
        <div onClick={() => setCollapsed((t) => !t)}>
          {collapsed ? (
            <span
              style={{
                position: "absolute",
                bottom: "10px",
                left: "17px",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "20px" }}
                alt="showMenu"
                src={expandMenuIcon}
              />
            </span>
          ) : (
            <div className="collapseIconStyles">
              <img
                alt="hideMenu"
                style={{ marginTop: "4px", width: "20px" }}
                src={collapseMenuIcon}
              />
            </div>
          )}
        </div>
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            margin: '8px 8px',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 8,
              minHeight: 360,
            }}
          >
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
    )
}

export default  AdminLayout;