import React, { useState } from "react";
import { Row, Col, Button, Card, Input, Dropdown, Menu, Image } from "antd";
import Addnew from "../../assets/images/addNew.svg";
import CloseField from "../../assets/images/close.svg";

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import LaptopImage from "../../assets/icons/laptop.png";

function Inputs({ setSelectedItems, setFinalInputs, apiEndPointData }) {
  console.log(apiEndPointData);
  const [isNewfield, setIsnewField] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [isNewType, setIsNewType] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [variableVisible, setVariableVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState('');
  const [initialFlag, setInitialFlag] = useState(apiEndPointData.inputs.length > 0 ? true : false);
  const addNewField = () => {
    setIsnewField(true);
    setInputValue('');
    setEditing(false);
    setInitialFlag(true);
  };
  const responsiveDesignNew = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };
  const responsiveDesignNew2 = {
    xxl: 23,
    xl: 23,
    lg: 23,
    xs: 23,
    sm: 23,
    md: 23,
  };
  const responsiveCol = {
    xxl: 5,
    xl: 5,
    lg: 5,
    xs: 5,
    sm: 5,
    md: 5,
  };
  const responsiveDiv = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 6,
    sm: 6,
    md: 6,
  };

  const responsiveSpace = {
    xxl: 1,
    xl: 1,
    lg: 1,
    xs: 1,
    sm: 1,
    md: 1,
  };
  const responsiveHeader = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 20,
    sm: 12,
    md: 8,
  };
  const responsiveColT = {
    xxl: 4,
    xl: 4,
    lg: 4,
    xs: 4,
    sm: 4,
    md: 4,
  };
  const responsiveDivText = {
    xxl: 20,
    xl: 20,
    lg: 20,
    xs: 20,
    sm: 20,
    md: 20,
  };

  const handleInput = (ev) => {
    setInputValue(ev.target.value);
  };

  const handleCard = (type) => () => {
    setFieldType(type);
    setIsNewType(true);
  };

  const handleEmaiButton = (index) => () => {
    setEditing(true);
    setEditingIndex(index);
    setIsNewType(true);
    setIsnewField(false);
    setInputValue(selectedFields[index].input);
    setFieldType(selectedFields[index].type);
  };

  const handleDelete = () => {
    let arr = [...selectedFields];
    arr.splice(editingIndex, 1);
    setSelectedFields(arr);
    setSelectedItems(arr);
    setFinalInputs(arr);
    setEditing(false);
    setInputValue('');
    setEditingIndex('');
    setIsNewType(false);
    setIsnewField(false);
  };

  const handleSave = () => {
    setIsNewType(false);
    setIsnewField(false);
    let arr = [...selectedFields];
    if (editing === true) {
      arr[editingIndex].input = inputValue;
    } else {
      arr.push({
        type : fieldType,
        input : inputValue
      });
    };
    setSelectedFields(arr);
    setSelectedItems(arr);
    setFinalInputs(arr);
    setEditing(false);
    setInputValue('');
    setEditingIndex('');
  };

  const handleVariableVisibleChange = (flag) => {
    setVariableVisible(flag);
  };

  const variableMenu = () => {
    return (
      <Menu
        key="1"
        style={{
          overflowY: "scroll",
          maxHeight: "15rem",
        }}
      >
        {selectedFields.map((item) => {
          return <Menu.Item key={item.input}>{item.input}</Menu.Item>;
        })}
      </Menu>
    );
  }

  return (
    <div
      style={{
        marginLeft: "12px",
        marginTop: "3px",
        fontFamily: "'Open Sans', sans-serif",
      }}
    >
      {selectedFields.length === 0 && initialFlag === false ?
        <div style={{ marginLeft: "400px", marginTop: "150px" }} >
          <Button style={{ backgroundColor: "#228F08", color: "white", height: "33px", borderBlockColor: "#1D7B07", marginLeft: "100px" }} onClick={addNewField}>
            Add New Input
          </Button>
          <br />
          <Image
            style={{ marginLeft: "30px" }}
            width={300}
            src={LaptopImage}
          />
          <br />
          <span style={{ color: "#888A8B", fontSize: "12px" }}>Specify if this endpoint accepts any inputs or query parameters</span>
        </div> :
        <Row>
          <Col {...responsiveDesignNew2}>
            <p className="inputTabH">
              Specify if this endpoint accepts any inputs or query parameters
            </p>
          </Col>
          <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
            <PlusSquareOutlined style={{ fontSize: '25px', color : "#228f08", cursor: "pointer" }} />
          </Dropdown>
          <Col {...responsiveDesignNew}>
            <Row>
              <Col {...responsiveCol}>
                {selectedFields.map((field, index) => {
                  return (
                    <>
                      <Button className="emaiButton" key={index} onClick={handleEmaiButton(index)}>
                        <p
                          style={{
                            color: "#707070",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {field.type}
                        </p>
                        <p
                          style={{
                            color: "#202223",
                            fontWeight: "600",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {field.input}
                        </p>
                      </Button>
                    &ensp;
                    </>
                  )
                })}
                &ensp;&ensp;
                <img
                    style={{ cursor: "pointer", width: "25px", marginTop: "10px" }}
                    src={Addnew}
                    alt="Addnew"
                    onClick={addNewField}
                />
              </Col>
              <Col {...responsiveSpace} />
              <Col {...responsiveDiv}>
                {isNewfield ? (
                  <div className="addNewWindow">
                    <Row className="addNewHeader">
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">Field Types</p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={() => {setIsnewField(false); setIsNewType(false); setInputValue(''); setEditing(false);}}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Text")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Text</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Title, Name, Description, etc.
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Integer")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Integer</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                A number without decimals
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Decimal")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Decimal</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                A number with decimals
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Boolean")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Boolean</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                true or false
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Object")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Object</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                A flexible structure with sub-elements
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </div>
                ) : null}
              </Col>
              {/* <Col {...responsiveSpace} /> */}
              <Col {...responsiveDiv}>
                {isNewType ? (
                  <div className="addNewWindow2">
                    <Row className="addNewHeader" >
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">Field Types</p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={() => {setIsNewType(false); setInputValue(''); setEditing(false);}}
                        />
                      </Col>
                    </Row>
                      <Card style={{ height : "350px", width : "100%"}}>
                        <Row>
                          <Col span={4}>
                            <Button className="emaiButton">
                              <p
                                style={{
                                  color: "#707070",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                Type
                              </p>
                              <p
                                style={{
                                  color: "#202223",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                {fieldType}
                              </p>
                            </Button>
                          </Col>
                          <Col span={2}>
                            <div style={{ marginLeft: "10px", marginTop: "10px", fontSize: "20px" }}>=</div>
                          </Col>
                          <Col span={18}>
                            <Input style={{ backgroundColor: "#2F2929", height : "3.4rem", color: "white" }} value={inputValue} onChange={handleInput} />
                          </Col>
                        </Row>
                        {/* {`Type : ${fieldType}`}
                        <Input value={inputValue} onChange={handleInput} /> */}
                      </Card>                 
                    <br />
                    <Row style={{ float: "right" }}>
                      {editing === true ? <Button style={{ marginRight : "450px", marginTop : "-8px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                      <Button style={{ marginRight : "12px", marginTop : "-8px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                    </Row>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </div>
  );
}

export default Inputs;
