import React, { useState, useEffect } from "react";
import { Form, Button, Input, message, Spin, Checkbox,Row,Col,Divider } from "antd";
import "antd/dist/antd.css";
import { useGlobalContext } from "../storage";
import { useHistory } from "react-router-dom";
import { getToken } from "../services/token";
import { LoadingOutlined } from "@ant-design/icons/lib/icons";
import noton from "../assets/icons/noton.svg";

const Login = () => {
    const { globalStore, setGlobalStore } = useGlobalContext();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const { authTokens } = globalStore;
      if (authTokens) {
        history.push("/");
      }
    }, []);

      const onLogin = async (values) => {
        setLoading(true);
        const username = values.username.trim();
        const password = values.password.trim();
        localStorage.clear();
        setGlobalStore({ authTokens: null });
        try {
          const resTokenData = await getToken(username, password);
          if (resTokenData) {
            localStorage.setItem("authTokens", JSON.stringify(resTokenData));
            setGlobalStore({authTokens : resTokenData});
            history.push("/");
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Login Failed:", error);
          if (JSON.parse(JSON.stringify(error)).message === "Request failed with status code 400") {
            message.error("Bad credentials, try again");
          } else {
            message.error("Some thing went wrong, Try again later");
          }
          localStorage.clear();
          setGlobalStore({ authTokens: null });
        }
      };

      const onLoginInvalid = () => {

      };

    return (
      <div
        style={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundColor: "$gray-1",
            backgroundColor: "#2F2929",
        }}
      >
        <div
          style={{
              marginTop: "18vh",
              maxWidth: "rem(380)",
              width: "388px",
              // height: "64vh",
              border: "1px solid white",
              boxShadow: "0 4px 38px 0 rgba(22, 21, 55, 0.06), 0 0 21px 0 rgba(22, 21, 55, 0.03)",
              paddingBottom: "2rem !important",
              padding: "24px !important",
              // backgroundColor: "#F5F5F5",
              background: "#F5F5F5 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              // opacity: 0.88
          }}
        >
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#239B07" }} />} spinning={loading}>
            <Row gutter={16}>
              <Col span={24} style={{textAlign:'center'}}>
                <img
                  alt="noton"
                  src={noton}
                  style={{marginTop:'5%'}}
                />
              </Col>
            </Row>
            <Form layout="vertical" style={{ marginTop: "2%", padding: "20px" }} onFinish={onLogin} onFinishFailed={onLoginInvalid}>
              <Form.Item
                name="username"
                style={{marginBottom:'13px'}}
                rules={[{ required: true, message: "Please input your Username!" }]}
              >
                <Input style={{ fontWeight: "500", height: "50px" }} placeholder="Username"/>
              </Form.Item>
              <Form.Item
                name="password"
                style={{marginBottom:'13px'}}
                rules={[{ required: true, message: "Please input your Password" }]}
              >
                <Input.Password type="password" style={{ fontWeight: "500", height: "50px" }} placeholder="Password" />
              </Form.Item>
                <Button
                  htmlType="submit"
                  style={{ backgroundColor: '#29B408', width: "100%", color: "white", borderBlockColor: "#239B07", height: "50px" }}
                >
                  <span style={{ color: 'white',fontWeight:'600' }}>Login</span>
                </Button>
            </Form>
            <Checkbox style={{ padding: "20px",paddingTop:'0px',paddingBottom:'0px', marginTop: "-20px",border:'1px solid #2F292' }} >Remember me</Checkbox>
             <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
              <Divider style={{marginTop:'12px',marginBottom:'12px'}}/>
             </div>
            <div style={{ padding: "20px",paddingTop:'0px',paddingBottom:'0px', color: "#1492E6" ,marginBottom:'5%'}} >Forgot Your Password?</div>
          </Spin>
        </div>
      </div>
    )
}

export default Login;