import React, { useState } from "react";
import { Layout, Menu } from "antd";
import Logo from "./assets/images/logo.png";
import LogoCollapse from "./assets/images/logoCollapse.svg";
import HomeIcon from "./assets/images/home.svg";
import Build from "./assets/images/build.svg";
import expandMenuIcon from "./assets/images/expandMenuIcon.svg";
import collapseMenuIcon from "./assets/images/collapseMenuIcon.svg";

import "antd/dist/antd.css";
import "./styles/antd.css";
import "./App.css";
import Home from "./components/home";

function App2() {
  const [collapsed, setCollapsed] = useState(false);
  const [isContent, setIsContent] = useState(false);

  const { Content, Sider } = Layout;
  const { SubMenu } = Menu;

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const NavigateToMenu = () => {
    setIsContent(true);
  };

  return (
    <Layout
      style={{ minHeight: "100vh", fontFamily: "'Open Sans', sans-serif" }}
    >
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        onCollapse={onCollapse}
        className={`${collapsed ? "siderCollapseStyles" : "siderStyles"}`}
      >
        <div
          style={{
            height: "50px",
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {!collapsed ? (
            <img
              style={{ cursor: "pointer", width: "97px", marginTop: "5px" }}
              src={Logo}
              alt="Logo"
            />
          ) : (
            <img
              style={{
                cursor: "pointer",
                width: "40px",
                marginTop: "8px",
                height: "30px",
              }}
              src={LogoCollapse}
              alt="Logo"
            />
          )}
        </div>
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            key="1"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Home
          </Menu.Item>
          <Menu.Item
            key="2"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Design
          </Menu.Item>
          <SubMenu
            key={"3"}
            style={{ paddingLeft: "-50px" }}
            icon={<img style={{ cursor: "pointer" }} src={Build} alt="Home" />}
            title={"Build"}
          >
            <Menu.Item
              key="3"
              style={{ paddingLeft: "50px" }}
              onClick={NavigateToMenu}
            >
              API
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="4"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Test
          </Menu.Item>
          <Menu.Item
            key="5"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Deploy
          </Menu.Item>
          <Menu.Item
            key="6"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Monitor
          </Menu.Item>

          <Menu.Item
            key="7"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Support
          </Menu.Item>
          <Menu.Item
            key="8"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Tools
          </Menu.Item>
          <Menu.Item
            key="9"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Message
          </Menu.Item>
          <Menu.Item
            key="10"
            style={{ paddingLeft: "15px" }}
            icon={
              <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            }
          >
            Settings
          </Menu.Item>
        </Menu>

        <div onClick={() => setCollapsed((t) => !t)}>
          {collapsed ? (
            <span
              style={{
                position: "absolute",
                bottom: "10px",
                left: "14px",
                cursor: "pointer",
              }}
            >
              <img
                style={{ width: "20px" }}
                alt="showMenu"
                src={expandMenuIcon}
              />
            </span>
          ) : (
            <div className="collapseIconStyles">
              <img
                alt="hideMenu"
                style={{ marginTop: "4px", width: "20px" }}
                src={collapseMenuIcon}
              />
            </div>
          )}
        </div>
      </Sider>
      {isContent ? (
        <Layout
          style={{ fontFamily: "'Open Sans', sans-serif" }}
          className="site-layout"
        >
          <Content style={{ margin: "0 20px" }}>
            <Home />
          </Content>
        </Layout>
      ) : null}
    </Layout>
  );
}

export default App2;
