import React from 'react'

 const DesignGroups = () => {
    return (
        <div>
            Design Groups
        </div>
    )
 }

 export default DesignGroups;