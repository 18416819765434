import React from 'react'
import {Row,Col,Button,Card,Image} from 'antd'
import {useHistory} from 'react-router-dom'
import LogoImage from "../../assets/images/cwSuiteLogo.png"
import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";

const ApplicationLayout = () =>{
    let history = useHistory()

    const routeToDesignGroup = () =>{
        history.push("/designgroups")
    }

    return (
        <div style={{backgroundColor:'#f0f2f5'}}>
            <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                    <h2 style={{letterSpacing:'0.52px',color:'#202223',opacity:'1',fontWeight:'bold',fontSize:'24px',marginBottom:'0px'}}>Application</h2>
                </Col>
                <Col className="gutter-row" style={{textAlign:'right'}} span={12}>
                    <Button size="small" style={{backgroundColor:'#228F08',border:'0.5px solid #1D7B07',color:'white',fontWeight:'500',fontSize:'14px',cursor:'pointer'}}>New App</Button>
                </Col>
            </Row>
            <br />
            <Row gutter={30} style={{marginLeft:'5%',marginRight:'5%'}}>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                    <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#0C4DA930',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                         <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                               <Image src={LogoImage} style={{width:'105px'}}/>
                            </Col>
                         </Row>
                         <br />
                         <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                               <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                            </Col>
                         </Row>   
                    </Card>
                </Col>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#FF8C0047',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                        <Image src={LogoImage} style={{width:'105px'}}/>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                    </Col>
                    </Row>  
                </Card>
                </Col>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                    <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#FFFFFF',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                            <Image src={LogoImage} style={{width:'105px'}}/>
                            </Col>
                        </Row>
                        <br />
                         <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                               <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                            </Col>
                         </Row>
                    </Card>
                </Col>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                    <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#FFFFFF',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                        <Image src={LogoImage} style={{width:'105px'}}/>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                    </Col>
                    </Row>
                    </Card>
                </Col>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                    <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#FFFFFF',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                        <Image src={LogoImage} style={{width:'105px'}}/>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                    </Col>
                    </Row>
                    </Card>
                </Col>
                <Col className="gutter-row" span={8} style={{marginBottom:'3%'}}>
                    <Card onClick ={routeToDesignGroup} style={{height:'35vh',background:'#FFFFFF',boxShadow:'1px 1px 2px #00000012',borderRadius:'12px',opacity:'1',padding:'15px',cursor:'pointer'}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={24}>
                            <Image src={LogoImage} style={{width:'105px'}}/>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <p style={{color:'#202223',marginBottom:'0px',fontSize:'13px'}}>Contains all XD links pertaining to screens in CW Platform including Layouts, Buttons, Icons, Dropdowns,Selectors, etc</p>
                        </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ApplicationLayout;