import React from 'react'

const  NewHome = () =>{
    return (
        <div>
            new Home page
        </div>
    )
}

export default  NewHome;