import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Input, Dropdown, Menu, Select, Image } from "antd";
import Addnew from "../../assets/images/addNew.svg";
import CloseField from "../../assets/images/close.svg";
import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
import LaptopImage from "../../assets/icons/laptop.png";

const { Option } = Select;

function Response({ selectedItems, setFinalResponse, finalFunctions, apiEndPointData }) {
  const [isNewfield, setIsnewField] = useState(false);
  const [inputSource, setInputSource] = useState('');
  const [inputResponse, setInputResponse] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [variableVisible, setVariableVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState('');
  const [initialFlag, setInitialFlag] = useState(apiEndPointData.responses.length > 0 ? true : false);
  const addNewField = () => {
    setIsnewField(true);
    setEditing(false);
    setInputSource('');
    setInputResponse('');
    setInitialFlag(true);
  };

  useEffect(() => {
    let arr = [...selectedFields];
    for (let index = 0; index < apiEndPointData.responses.length; index++) {
      arr.push({
        source : apiEndPointData.responses[index].sourceVar,
        response : apiEndPointData.responses[index].resposeVar
      });
    };
    setSelectedFields(arr);
    setFinalResponse(arr);
    setIsnewField(false);
    setEditing(false);
    setInputSource('');
    setInputResponse('');
    setEditingIndex('');
  }, []);

  const responsiveDesignNew = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };
  const responsiveDesignNew2 = {
    xxl: 23,
    xl: 23,
    lg: 23,
    xs: 23,
    sm: 23,
    md: 23,
  };
  const responsiveCol = {
    xxl: 5,
    xl: 5,
    lg: 5,
    xs: 5,
    sm: 5,
    md: 5,
  };
  const responsiveDiv = {
    xxl: 6,
    xl: 6,
    lg: 6,
    xs: 6,
    sm: 6,
    md: 6,
  };

  const responsiveSpace = {
    xxl: 1,
    xl: 1,
    lg: 1,
    xs: 1,
    sm: 1,
    md: 1,
  };
  const responsiveHeader = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 20,
    sm: 12,
    md: 8,
  };
  // const responsiveColT = {
  //   xxl: 4,
  //   xl: 4,
  //   lg: 4,
  //   xs: 4,
  //   sm: 4,
  //   md: 4,
  // };
  // const responsiveDivText = {
  //   xxl: 20,
  //   xl: 20,
  //   lg: 20,
  //   xs: 20,
  //   sm: 20,
  //   md: 20,
  // };

  const handleVariableVisibleChange = (flag) => {
    setVariableVisible(flag);
  };

  const variableMenu = () => {
    return (
      <Menu
        key="1"
        style={{
          overflowY: "scroll",
          maxHeight: "15rem",
        }}
      >
        {selectedItems.map((item) => {
          return <Menu.Item key={item.input}>{item.input}</Menu.Item>;
        })}
      </Menu>
    );
  };

  const handleSourecVar = (ev) => {
    setInputSource(ev);
  };

  const handleResponseVar = (ev) => {
    setInputResponse(ev.target.value);
  }

  const handleEmaiButton = (index) => () => {
    setEditing(true);
    setEditingIndex(index);
    setIsnewField(true);
    setInputSource(selectedFields[index].source);
    setInputResponse(selectedFields[index].response);
  };

  const handleDelete = () => {
    let arr = [...selectedFields];
    arr.splice(editingIndex, 1);
    setSelectedFields(arr);
    setFinalResponse(arr);
    setEditing(false);
    setInputSource('');
    setInputResponse('');
    setEditingIndex('');
    setIsnewField(false);
  };

  const handleSave = () => {
    let arr = [...selectedFields];
    if (editing === true) {
      arr[editingIndex].source = inputSource;
      arr[editingIndex].response = inputResponse;
    } else {
      arr.push({
          source : inputSource,
          response : inputResponse
      });
    };
    setSelectedFields(arr);
    setFinalResponse(arr);
    setIsnewField(false);
    setEditing(false);
    setInputSource('');
    setInputResponse('');
    setEditingIndex('');
  };

  return (
    <div
      style={{
        marginLeft: "12px",
        marginTop: "3px",
        fontFamily: "'Open Sans', sans-serif",
      }}
    >
      {selectedFields.length === 0 && initialFlag === false ?
        <div style={{ marginLeft: "400px", marginTop: "150px" }} >
          <Button style={{ backgroundColor: "#228F08", color: "white", height: "33px", borderBlockColor: "#1D7B07", marginLeft: "100px" }} onClick={addNewField}>
            Add New Responses
          </Button>
          <br />
          <Image
            style={{ marginLeft: "30px" }}
            width={300}
            src={LaptopImage}
          />
          <br />
          <span style={{ color: "#888A8B", fontSize: "12px" }}>Specify if this endpoint accepts any Response or query parameters</span>
        </div> :
        <Row>
          <Col {...responsiveDesignNew2}>
            <p className="inputTabH">
              Specify if this endpoint accepts any Response or query parameters
            </p>
          </Col>
          <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
            <PlusSquareOutlined style={{ fontSize: '25px', color : "#228f08", cursor: "pointer" }} />
          </Dropdown>
          <Col {...responsiveDesignNew}>
            <Row>
              <Col {...responsiveCol}>
                {selectedFields.map((field, index) => {
                  return (
                    <>
                      <Button className="emaiButton" key={index} onClick={handleEmaiButton(index)}>
                      <p
                          style={{
                            color: "#202223",
                            fontWeight: "600",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {field.source}
                        </p>
                        <p
                          style={{
                            color: "#202223",
                            fontWeight: "600",
                            fontSize: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {field.response}
                        </p>
                      </Button>
                    &ensp;
                    </>
                  )
                })}
                &ensp;&ensp;
                <img
                  style={{ cursor: "pointer", width: "25px", marginTop: "10px" }}
                  src={Addnew}
                  alt="Addnew"
                  onClick={addNewField}
                />
              </Col>
              <Col {...responsiveSpace} />
              <Col {...responsiveDiv}>
                {isNewfield ? (
                  <div className="addNewWindow2">
                    <Row className="addNewHeader">
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">Response</p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={() => {setIsnewField(false); setEditing(false); setInputSource(''); setInputResponse('');}}
                        />
                      </Col>
                    </Row>
                    <Row style={{ height : "362px"}}>
                      <Card className="contentCard">
                        What would you like to return?
                        <br />
                        <span style={{ color: "grey" }}>CW will return what you select</span>
                        <br />
                        <br />
                      <Row>
                          <Col span={6}>
                            <Button className="emaiButton">
                              <p
                                style={{
                                  color: "#202223",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                SourceVar
                              </p>
                            </Button>
                          </Col>
                          <Col span={2}>
                            <div style={{ marginLeft: "21px", marginTop: "1px", fontSize: "20px" }}>=</div>
                          </Col>
                          <Col span={16}>
                            <Select
                              value={inputSource}
                              showSearch
                              style={{ width: "90%" }}
                              // style={{ width: "90%", backgroundColor: "#2F2929", color: "white" }}
                              // dropdownStyle={{ backgroundColor: "#2F2929" }}
                              placeholder="Select SourceVar"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={handleSourecVar}
                            >
                              {finalFunctions.map(item => {
                                return (
                                  <Option value={item.output}>{item.output}</Option>
                                )
                              })}
                            </Select>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col span={6}>
                            <Button className="emaiButton">
                              <p
                                style={{
                                  color: "#202223",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                ResponseVar
                              </p>
                            </Button>
                          </Col>
                          <Col span={2}>
                            <div style={{ marginLeft: "21px", marginTop: "1px", fontSize: "20px" }} >=</div>
                          </Col>
                          <Col span={16}>
                            <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={inputResponse} onChange={handleResponseVar} />
                          </Col>
                        </Row>
                        {/* <h5>sourceVar</h5>
                        <Select
                          value={inputSource}
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select SourceVar"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={handleSourecVar}
                        >
                          {finalFunctions.map(item => {
                            return (
                              <Option value={item.output}>{item.output}</Option>
                            )
                          })}
                        </Select>
                        <br />
                        <h5>ResponseVar</h5>
                        <Input value={inputResponse} onChange={handleResponseVar} /> */}
                      </Card>
                    </Row>
                    <Row style={{ float: "right" }}>
                      {editing === true ? <Button style={{ marginRight : "455px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                      <Button style={{ marginRight : "10px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                    </Row>
                    {/* <Row style={{ float: "right" }}>
                      {editing === true ? <Button style={{ marginRight : "12px", marginTop : "-8px" }} onClick={handleDelete}>Delete</Button> : ""}
                      <Button style={{ marginRight : "12px", marginTop : "-8px" }} onClick={handleSave}>Save</Button>
                    </Row> */}
                  </div>
                ) : null}
              </Col>
              {/* <Col {...responsiveSpace} />
              <Col {...responsiveDiv}>
                {isNewType ? (
                  <div className="addNewWindow">
                    <Row className="addNewHeader" >
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">Field Types</p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={() => {setIsNewType(!isNewType)}}
                        />
                      </Col>
                    </Row>
                    <Row style={{ height : "350px"}}>
                      <Card className="contentCard">
                        {`Type : ${fieldType}`}
                        <Input onChange={handleInput} />
                      </Card>
                    </Row>
                    <br />
                    <Row style={{ float: "right" }}>
                      <Button style={{ marginRight : "12px", marginTop : "-8px" }} onClick={handleSave}>Save</Button>
                    </Row>
                  </div>
                ) : null}
              </Col> */}
            </Row>
          </Col>
        </Row>
      }
    </div>
  );
}

export default Response;
