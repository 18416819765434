import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Input, Dropdown, Menu, Form, Space, Select, Divider, Typography, Tabs, Image } from "antd";
import BackNavigate from "../../assets/images/back.svg";
import CloseField from "../../assets/images/close.svg";
import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import { PlusSquareOutlined, MinusCircleOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import LaptopImage from "../../assets/icons/laptop.png";
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

let index = 0;

function Functions({ selectedItems, setFinalFunctions, apiEndPointData }) {
  const [mainForm] = Form.useForm();
  const [isNewType, setIsnewType] = useState(false);
  const [isNewCard, setIsnewCard] = useState(false);
  const [fieldType, setFieldType] = useState('');
  const [variableVisible, setVariableVisible] = useState(false);
  const [variableDropdownVisible, setVariableDropdownVisible] = useState(false);
  const [changedQuery, setChangedQuery] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [apiMethod, setApiMethod] = useState('');
  const [apiParams, setApiParams] = useState('');
  const [apiHeaders, setApiHeaders] = useState([]);
  const [apiTimeout, setApiTimeout] = useState('');
  const [apiFollowLocation, setApiFollowLocation] = useState('');
  const [dbConfig, setDbConfig] = useState({database: "testdb", host: "localhost", password: "testpassword", port: 5432, user: "postgres"});
  const [dbrLength, setDbrLength] = useState([]);
  const [dbrOutput, setDbrOutput] = useState(`user_${dbrLength.length+1}`);
  const [apiLength, setApiLength] = useState([]);
  const [apiOutput, setApiOutput] = useState(`api_${apiLength.length+1}`);
  const [savedItems, setSavedItems] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState('');
  const [headerItems, setHeaderItems] = useState(['Content-Type', 'Authorization']);
  const [customHeader, setCustomHeader] = useState('');
  const [initialFlag, setInitialFlag] = useState(apiEndPointData.functions.length > 0 ? true : false);

  useEffect(() => {
    let arr = [...savedItems];
    for (let index = 0; index < apiEndPointData.functions.length; index++) {
      if (apiEndPointData.functions[index].type === "EAPI") {
        arr.push({
          name : "External API Request",
          method : apiEndPointData.functions[index].requestConfig.method,
          url : apiEndPointData.functions[index].requestConfig.url,
          data : apiEndPointData.functions[index].requestConfig.data,
          headers : [{
            type : Object.keys(apiEndPointData.functions[index].requestConfig.headers),
            value : Object.values(apiEndPointData.functions[index].requestConfig.headers)
          }],
          output : apiEndPointData.functions[index].funcResVar
        });
        let apiLengthArr = [...apiLength];
        apiLengthArr.push({
          url : apiUrl
        });
        setApiLength(apiLengthArr);
        setApiOutput(`api_${apiLengthArr.length+1}`);
        mainForm.setFieldsValue({ headers : [{
          type : Object.keys(apiEndPointData.functions[index].requestConfig.headers),
          value : Object.values(apiEndPointData.functions[index].requestConfig.headers)
        }] })
      } else if (apiEndPointData.functions[index].type === "DBR") {
        arr.push({
          name : "Database Requests",
          query : apiEndPointData.functions[index].query,
          output: apiEndPointData.functions[index].funcResVar,
          dbConfig: apiEndPointData.functions[index].dbConfig
        });
        let dbrLengthArr = [...dbrLength];
        dbrLengthArr.push({
          query : changedQuery, 
        });
        setDbrLength(dbrLengthArr);
        setDbrOutput(`user_${dbrLengthArr.length+1}`);
      } else {
        arr.push({
          name : fieldType,
          query : changedQuery
        });
      };
    }
    setSavedItems(arr);
    setFinalFunctions(arr);
    setIsnewCard(false);
    setChangedQuery('');
    setApiUrl('');
    setApiMethod('');
    setApiHeaders([]);
    setApiParams('');
    setApiTimeout('');
    setApiFollowLocation('');
    setEditing(false);
    setEditingIndex('');
    mainForm.resetFields();
  }, []);

  console.log(savedItems)

  const addnewType = () => {
    setIsnewType(true);
    setIsnewCard(false);
    setChangedQuery('');
    setApiUrl('');
    setApiMethod('');
    setApiHeaders([]);
    setApiParams('');
    setApiTimeout('');
    setApiFollowLocation('');
    setEditing(false);
    setEditingIndex('');
    mainForm.resetFields();
    setInitialFlag(true);
  };

  // const addnewCard = () => {
  //   setIsnewCard(!isNewCard);
  //   setIsnewType(false);
  // };

  const addNewField = () => {
    setIsnewType(false);
    setChangedQuery('');
    setApiUrl('');
    setApiMethod('');
    setApiHeaders([]);
    setApiParams('');
    setApiTimeout('');
    setApiFollowLocation('');
    setEditing(false);
    setEditingIndex('');
    mainForm.resetFields();
  };

  const responsiveDesignNew = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };
  const responsiveDesignNew2 = {
    xxl: 23,
    xl: 23,
    lg: 23,
    xs: 23,
    sm: 23,
    md: 23,
  };
  // const responsiveCol = {
  //   xxl: 5,
  //   xl: 5,
  //   lg: 5,
  //   xs: 5,
  //   sm: 5,
  //   md: 5,
  // };
  // const responsiveDiv = {
  //   xxl: 18,
  //   xl: 18,
  //   lg: 18,
  //   xs: 18,
  //   sm: 18,
  //   md: 18,
  // };

  // const responsiveSpace = {
  //   xxl: 1,
  //   xl: 1,
  //   lg: 1,
  //   xs: 1,
  //   sm: 1,
  //   md: 1,
  // };
  const responsiveHeader = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 20,
    sm: 12,
    md: 8,
  };
  const responsiveColT = {
    xxl: 4,
    xl: 4,
    lg: 4,
    xs: 4,
    sm: 4,
    md: 4,
  };
  // const responsiveColIcon = {
  //   xxl: 3,
  //   xl: 3,
  //   lg: 3,
  //   xs: 3,
  //   sm: 3,
  //   md: 3,
  // };
  const responsiveDivText = {
    xxl: 20,
    xl: 20,
    lg: 20,
    xs: 20,
    sm: 20,
    md: 20,
  };

  const handleCard = (type) => () => {
    setFieldType(type);
    setIsnewType(!isNewType);
    setIsnewCard(!isNewCard);
  };

  const handleVariableVisibleChange = (flag) => {
    setVariableVisible(flag);
  };

  const handleVariableVisibleDropdownChange = (flag) => {
    setVariableDropdownVisible(flag);
  };

  const handleVariables = (e) => {
    let item1 = changedQuery;
    let item2 = e.key;
    let result = item1.concat(item2)
    setChangedQuery(result);
    setVariableVisible(false);
  }

  const variableMenu = () => {
    return (
      <Menu
        key="1"
        style={{
          overflowY: "scroll",
          maxHeight: "15rem",
        }}
        onClick={handleVariables}
      >
        {selectedItems.map((item) => {
          return <Menu.Item key={item.input}>{item.input}</Menu.Item>;
        })}
      </Menu>
    );
  };

  const handleQuery = (ev) => {
    setChangedQuery(ev.target.value);
  };

  const handleUrl = (ev) => {
    setApiUrl(ev.target.value);
  };

  const handleMethod = (ev) => {
    setApiMethod(ev);
  };

  const handleParams = (ev) => {
    setApiParams(ev.target.value);
  };

  const handleValuesChange = (changedFields, allFields) => {
    setApiHeaders(allFields.headers);
  };

  const handleTimeout = (ev) => {
    setApiTimeout(ev.target.value);
  };

  const handleFollowLocation = (ev) => {
    setApiFollowLocation(ev.target.value);
  };

  const handleApiOutput = (ev) => {
    setApiOutput(ev.target.value);
  };

  const handleDbrOutput = (ev) => {
    setDbrOutput(ev.target.value);
  };

  const onCustomHeaderChange = (ev) => {
    setCustomHeader(ev.target.value);
  };

  const addItem = e => {
    e.preventDefault();
    setHeaderItems([...headerItems, customHeader  || `Custom header ${index++}`]);
    setCustomHeader('');
  }

  const handleSavedItems = (index) => () => {
    setEditing(true);
    setEditingIndex(index);
    setIsnewCard(true);
    setIsnewType(false);
    setFieldType(savedItems[index].name);
    if (savedItems[index].name === "External API Request") {
      setApiUrl(savedItems[index].url);
      setApiMethod(savedItems[index].method);
      setApiHeaders(savedItems[index].headers);
      setApiParams(savedItems[index].data);
      setApiOutput(savedItems[index].output);
      mainForm.setFieldsValue({ headers : savedItems[index].headers });
    } else {
      setChangedQuery(savedItems[index].query);
      setDbrOutput(savedItems[index].output);
    }
  };

  const handleDelete = () => {
    let arr = [...savedItems];
    arr.splice(editingIndex, 1);
    setSavedItems(arr);
    setFinalFunctions(arr);
    setIsnewCard(false);
    setChangedQuery('');
    setApiUrl('');
    setApiMethod('');
    setApiHeaders([]);
    setApiParams('');
    setApiTimeout('');
    setApiFollowLocation('');
    setEditing(false);
    setEditingIndex('');
    mainForm.resetFields();
  };

  const handleSave = () => {
    let arr = [...savedItems];
    if (editing === true) {
      if (arr[editingIndex].name === "External API Request") {
        arr[editingIndex].name = fieldType;
        arr[editingIndex].method = apiMethod;
        arr[editingIndex].url = apiUrl;
        arr[editingIndex].data = apiParams;
        arr[editingIndex].headers = apiHeaders;
        arr[editingIndex].output = apiOutput;
      } else {
        arr[editingIndex].name = fieldType;
        arr[editingIndex].query = changedQuery;
        arr[editingIndex].output= dbrOutput
      }
    } else {
      if (fieldType === "External API Request") {
        arr.push({
          name : fieldType,
          method : apiMethod,
          url : apiUrl,
          data : apiParams,
          headers : apiHeaders,
          output : apiOutput
        });
        let apiLengthArr = [...apiLength];
        apiLengthArr.push({
          url : apiUrl
        });
        setApiLength(apiLengthArr);
        setApiOutput(`api_${apiLengthArr.length+1}`);
      } else if (fieldType === "Database Requests") {
        arr.push({
          name : fieldType,
          query : changedQuery,
          output: dbrOutput,
          dbConfig: dbConfig
        });
        let dbrLengthArr = [...dbrLength];
        dbrLengthArr.push({
          query : changedQuery, 
        });
        setDbrLength(dbrLengthArr);
        setDbrOutput(`user_${dbrLengthArr.length+1}`);
      } else {
        arr.push({
          name : fieldType,
          query : changedQuery
        });
      };
    };
    setSavedItems(arr);
    setFinalFunctions(arr);
    setIsnewCard(false);
    setChangedQuery('');
    setApiUrl('');
    setApiMethod('');
    setApiHeaders([]);
    setApiParams('');
    setApiTimeout('');
    setApiFollowLocation('');
    setEditing(false);
    setEditingIndex('');
    mainForm.resetFields();
  };

  return (
    <div
      style={{
        marginLeft: "12px",
        marginTop: "3px",
        fontFamily: "'Open Sans', sans-serif",
      }}
    >
      {savedItems.length === 0 && initialFlag === false ?
        <div style={{ marginLeft: "400px", marginTop: "150px" }} >
          <Button style={{ backgroundColor: "#228F08", color: "white", height: "33px", borderBlockColor: "#1D7B07", marginLeft: "100px" }} onClick={addNewField}>
            Add New Functions
          </Button>
          <br />
          <Image
            style={{ marginLeft: "40px" }}
            width={300}
            src={LaptopImage}
          />
          <br />
          <span style={{ color: "#888A8B", fontSize: "12px" }}>Add functions below to determine how your endpoint gets processed</span>
        </div> :
        <Row>
          <Col {...responsiveDesignNew2}>
            <p className="inputTabH">
              Add functions below to determine how your endpoint gets processed{" "}
            </p>
          </Col>
          <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleDropdownChange} visible={variableDropdownVisible}>
            <PlusSquareOutlined style={{ fontSize: '25px', color : "#228f08", cursor: "pointer" }} />
          </Dropdown>
          <Col {...responsiveDesignNew}>
            <Row>
              <Col {...responsiveHeader}>
                <div className="functionDiv">
                  {savedItems.map((item, index) => {
                    return <Row
                      style={{ borderBottom: "1px solid #DEDEDE", padding: "15px" }}
                      key = {index}
                    >
                      <p style={{ marginBottom: "0px", cursor: "pointer" }} onClick={handleSavedItems(index)}>
                        <span className="numericStyles">{index + 1}</span>{" "}
                        <span style={{ font: "bold" }}>{item.name}</span>{" "}
                        <span className="textStyles">
                          {item.name === "External API Request" ? `API : ${item.url}` :item.query}
                        </span>{" "}
                        <span style={{ color : "#C5C3C3" }}>return as</span>{" "}
                        <span style={{ color: "#F87915"}}>{item.output}</span>
                      </p>
                    </Row>
                  })}
                  <Row style={{ padding: "15px" }}>
                    <p className="addNewButton" onClick={addnewType}>
                      + Add New
                    </p>
                  </Row>
                </div>
              </Col>
              {isNewType ? (
                <Col style={{ paddingLeft: "12px" }} {...responsiveHeader}>
                  <div className="addNewWindow">
                    <Row className="addNewHeader">
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">Add Function</p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={addNewField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Database Requests")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Database Requests</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Database Request Methods
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Data Manipulation")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Data Manipulation</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Math, Variables, Arrays, Loops and more...
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Security")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Security</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Tokens, UUID, Algorithms and more...
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Data Caching (Redis)")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Data Caching (Redis)</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Store data temporarily
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Custom Functions")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Custom Functions</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Created by you or the marketplace
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Utility Functions")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Utility Functions</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Misc helper functions
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("Content Upload")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">Content Upload</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Images, Videos, Attachments, etc...
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard" style={{ cursor: "pointer" }} onClick={handleCard("External API Request")}>
                        <Row>
                          <Col {...responsiveColT}>
                            <p className="TStyles">T</p>
                          </Col>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="textStyles">External API Request</p>
                            </Row>
                            <Row>
                              <p className="descriptionStyles">
                                Access and process an external endpoint
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </div>
                </Col>
              ) : null}

              {isNewCard ? (
                <Col style={{ paddingLeft: "12px" }} {...responsiveHeader}>
                  <div className="addNewWindow2">
                    <Row className="addNewHeader">
                      <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                        <p className="fieldHeading">
                          {editing === false ? <span>
                            <img
                              style={{
                                cursor: "pointer",
                                marginRight: "1px",
                                width: "30px",
                                marginTop: "-3px",
                              }}
                              src={BackNavigate}
                              alt="Logo"
                              onClick={() => {
                                setIsnewType(true);
                                setIsnewCard(false); 
                                setChangedQuery('');
                                setApiUrl('');
                                setApiMethod('');
                                setApiHeaders([]);
                                setApiParams('');
                                setApiTimeout('');
                                setApiFollowLocation('');
                                setEditing(false);
                                setEditingIndex('');
                              }}
                            />
                          </span> : ""}
                          Add Function
                        </p>
                      </Col>
                      <Col style={{ textAlign: "right" }} {...responsiveHeader}>
                        <img
                          style={{
                            cursor: "pointer",
                            width: "25px",
                          }}
                          src={CloseField}
                          alt="CloseField"
                          onClick={() => {
                            setIsnewCard(false); 
                            setChangedQuery('');
                            setApiUrl('');
                            setApiMethod('');
                            setApiHeaders([]);
                            setApiParams('');
                            setApiTimeout('');
                            setApiFollowLocation('');
                            setEditing(false);
                            setEditingIndex('');
                            mainForm.resetFields();
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {fieldType === "Database Requests" ?
                      <>
                        <h2>{fieldType}</h2>
                        <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
                          <Button style={{ marginLeft : "320px", marginTop : "2px", marginBottom: "2px" }}>Insert</Button>
                        </Dropdown>
                        <Tabs style={{ width: "100%" }}>
                          <TabPane tab="Inputs" key="1">
                            <TextArea rows={12} value={changedQuery} onChange={handleQuery} size='small' style={{ marginLeft : "5px", marginRight : "5px", width: "98%" }} />
                          </TabPane>
                          <TabPane tab="Output" key="2">
                            <Card style={{ height: "270px" }}>
                              <span style={{ color: "blue" }} >RETURN AS</span>
                              <br />
                              Set the variable name.
                              <br />
                              <br />
                              <Row>
                                <Col span={4}>
                                  <Button className="emaiButton" style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        color: "#202223",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      AS
                                    </p>
                                  </Button>
                                </Col>
                                <Col span={2}>
                                  <div style={{ fontSize: "20px", marginLeft: "-3px"  }}>=</div>
                                </Col>
                                <Col span={18}>
                                  <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={dbrOutput} onChange={handleDbrOutput} />
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                        </Tabs>
                        <Row style={{ float: "right" }}>
                          {editing === true ? <Button style={{ marginLeft : "5px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                          <Button style={{ marginLeft : editing === true ? "461px" : "515px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                        </Row>
                      </> : 
                      fieldType === "Data Manipulation" ?
                      <>
                        <h2>{fieldType}</h2>
                        <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
                          <Button style={{ marginLeft : "320px", marginTop : "2px", marginBottom: "2px" }}>Insert</Button>
                        </Dropdown>
                        <Tabs style={{ width: "100%" }}>
                          <TabPane tab="Inputs" key="1">
                            <TextArea rows={12} value={changedQuery} onChange={handleQuery} size='small' style={{ marginLeft : "5px", marginRight : "5px", width: "98%" }} />
                          </TabPane>
                          <TabPane tab="Output" key="2">
                            <Card style={{ height: "270px" }}>
                              <span style={{ color: "blue" }} >RETURN AS</span>
                              <br />
                              Set the variable name.
                              <br />
                              <br />
                              <Row>
                                <Col span={4}>
                                  <Button className="emaiButton" style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        color: "#202223",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      AS
                                    </p>
                                  </Button>
                                </Col>
                                <Col span={2}>
                                  <div style={{ fontSize: "20px", marginLeft: "-3px"  }}>=</div>
                                </Col>
                                <Col span={18}>
                                  <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={dbrOutput} onChange={handleDbrOutput} />
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                        </Tabs>
                        <Row style={{ float: "right" }}>
                          {editing === true ? <Button style={{ marginLeft : "5px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                          <Button style={{ marginLeft : editing === true ? "461px" : "515px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                        </Row>
                      </> :
                      fieldType === "Security" ?
                      <>
                        <h2>{fieldType}</h2>
                        <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
                          <Button style={{ marginLeft : "320px", marginTop : "2px", marginBottom: "2px" }}>Insert</Button>
                        </Dropdown>
                        <Tabs style={{ width: "100%" }}>
                          <TabPane tab="Inputs" key="1">
                            <TextArea rows={12} value={changedQuery} onChange={handleQuery} size='small' style={{ marginLeft : "5px", marginRight : "5px", width: "98%" }} />
                          </TabPane>
                          <TabPane tab="Output" key="2">
                            <Card style={{ height: "270px" }}>
                              <span style={{ color: "blue" }} >RETURN AS</span>
                              <br />
                              Set the variable name.
                              <br />
                              <br />
                              <Row>
                                <Col span={4}>
                                  <Button className="emaiButton" style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        color: "#202223",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      AS
                                    </p>
                                  </Button>
                                </Col>
                                <Col span={2}>
                                  <div style={{ fontSize: "20px", marginLeft: "-3px"  }}>=</div>
                                </Col>
                                <Col span={18}>
                                  <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={dbrOutput} onChange={handleDbrOutput} />
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                        </Tabs>
                        <Row style={{ float: "right" }}>
                          {editing === true ? <Button style={{ marginLeft : "5px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                          <Button style={{ marginLeft : editing === true ? "461px" : "515px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                        </Row>
                      </> :
                      fieldType === "Data Caching (Redis)" ?
                      <>
                      <h2>{fieldType}</h2>
                      </> :
                      fieldType === "Custom Functions" ?
                      <>
                      <h2>{fieldType}</h2>
                      </> :
                      fieldType === "Utility Functions" ?
                      <>
                        <h2>{fieldType}</h2>
                        <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
                          <Button style={{ marginLeft : "320px", marginTop : "2px", marginBottom: "2px" }}>Insert</Button>
                        </Dropdown>
                        <Tabs style={{ width: "100%" }}>
                          <TabPane tab="Inputs" key="1">
                            <TextArea rows={12} value={changedQuery} onChange={handleQuery} size='small' style={{ marginLeft : "5px", marginRight : "5px", width: "98%" }} />
                          </TabPane>
                          <TabPane tab="Output" key="2">
                            <Card style={{ height: "270px" }}>
                              <span style={{ color: "blue" }} >RETURN AS</span>
                              <br />
                              Set the variable name.
                              <br />
                              <br />
                              <Row>
                                <Col span={4}>
                                  <Button className="emaiButton" style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        color: "#202223",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      AS
                                    </p>
                                  </Button>
                                </Col>
                                <Col span={2}>
                                  <div style={{ fontSize: "20px", marginLeft: "-3px"  }}>=</div>
                                </Col>
                                <Col span={18}>
                                  <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={dbrOutput} onChange={handleDbrOutput} />
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                        </Tabs>
                        <Row style={{ float: "right" }}>
                          {editing === true ? <Button style={{ marginLeft : "5px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                          <Button style={{ marginLeft : editing === true ? "461px" : "515px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                        </Row>
                      </> :
                      fieldType === "Content Upload" ?
                      <>
                      <h2>{fieldType}</h2>
                      <Card className="contentCard">
                        <Row>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="addText">Create Image Metadata</p>
                            </Row>
                            <Row>
                              <p className="addDescription">
                                Create image metadata from a File resource
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="contentCard">
                        <Row>
                          <Col {...responsiveDivText}>
                            <Row>
                              <p className="addText">Create File Resource</p>
                            </Row>
                            <Row>
                              <p className="addDescription">
                                Create a File resource from a variable
                              </p>{" "}
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      </> :
                      <>
                        <h2>{fieldType}</h2>
                        <Dropdown trigger={["click"]} overlay={variableMenu} onVisibleChange={handleVariableVisibleChange} visible={variableVisible}>
                          <Button style={{ marginLeft : "305px", marginTop : "2px", marginBottom: "2px" }}>Insert</Button>
                        </Dropdown>
                        <Tabs style={{ width: "100%" }}>
                          <TabPane tab="Inputs" key="1">
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    TEXT
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    url
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}>=</div>
                              </Col>
                              <Col span={18}>
                                <Input style={{ backgroundColor: "#2F2929", height : "3.4rem", color: "white", width: "90%" }} value={apiUrl} onChange={handleUrl} />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    ENUM
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    method
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}>=</div>
                              </Col>
                              <Col span={18}>
                                <Select 
                                  value={apiMethod}
                                  onChange={handleMethod}
                                  size={"large"}
                                  style={{ width: "90%" }}
                                  // style={{ width: "90%", backgroundColor: "#2F2929", color: "white" }}
                                  // dropdownStyle={{ backgroundColor: "#2F2929" }}
                                  placeholder="Select Method"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                                >
                                  <Option value="GET">GET</Option>
                                  <Option value="POST">POST</Option>
                                </Select>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    ANY
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    params
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}>=</div>
                              </Col>
                              <Col span={18}>
                                <Input style={{ backgroundColor: "#2F2929", height : "3.4rem", color: "white", width: "90%" }} value={apiParams} onChange={handleParams} />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    TEXT[]
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    headers
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}>=</div>
                              </Col>
                              <Col span={18}>
                                <Card style={{ width: "90%", backgroundColor: "#2F2929" }}>
                                  <Form form={mainForm} autoComplete="off" onValuesChange={handleValuesChange}>
                                    <Form.List name="headers">
                                      {(fields, { add, remove }) => (
                                        <>
                                          <Form.Item>
                                            <Button type="dashed" onClick={() => add()} style={{ marginLeft: "120px" }} >
                                              Add Headers
                                            </Button>
                                          </Form.Item>
                                          {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'type']}
                                                rules={[{ required: true, message: 'Missing type' }]}
                                              >
                                                <Select
                                                  showSearch
                                                  style={{ width: 180 }}
                                                  // style={{ width: 180, backgroundColor: "#2F2929", color: "white", border: "1px #d9d9d9" }}
                                                  // dropdownStyle={{ backgroundColor: "#2F2929" }}
                                                  placeholder="Select Type"
                                                  optionFilterProp="children"
                                                  filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                  }
                                                  filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                  }
                                                  dropdownRender={menu => (
                                                    <>
                                                      {menu}
                                                      <Divider style={{ margin: '8px 0' }} />
                                                      <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Custom Header" value={customHeader} onChange={onCustomHeaderChange} />
                                                        <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                                                          <PlusOutlined />
                                                        </Typography.Link>
                                                      </Space>
                                                    </>
                                                  )}
                                                >
                                                  {headerItems.map(item => (
                                                    <Option key={item}>{item}</Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true, message: 'Missing value' }]}
                                              >
                                                <Input placeholder="Value" style={{ backgroundColor: "#2F2929", color: "white" }} />
                                              </Form.Item>
                                              <MinusCircleOutlined onClick={() => remove(name)} style={{ color: "white" }} />
                                            </Space>
                                          ))}
                                        </>
                                      )}
                                    </Form.List>
                                  </Form>
                                </Card>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    INTEGER
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    timeout
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}>=</div>
                              </Col>
                              <Col span={18}>
                                <Input style={{ backgroundColor: "#2F2929", height : "3.4rem", color: "white", width: "90%" }} value={apiTimeout} onChange={handleTimeout} />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col span={4}>
                                <Button className="emaiButton" style={{ marginLeft : "5px" }}>
                                  <p
                                    style={{
                                      color: "#707070",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    BOOL
                                  </p>
                                  <p
                                    style={{
                                      color: "#202223",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    follow_location
                                  </p>
                                </Button>
                              </Col>
                              <Col span={2}>
                                <div style={{ marginLeft: "5px", marginTop: "10px", fontSize: "20px" }}></div>
                              </Col>
                              <Col span={18}>
                                <Input style={{ backgroundColor: "#2F2929", height : "3.4rem", color: "white", width: "90%" }} value={apiFollowLocation} onChange={handleFollowLocation} />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tab="Output" key="2">
                            <Card style={{ height: "270px" }}>
                              <span style={{ color: "blue" }} >RETURN AS</span>
                              <br />
                              Set the variable name.
                              <br />
                              <br />
                              <Row>
                                <Col span={4}>
                                  <Button className="emaiButton" style={{ marginLeft: "5px" }}>
                                    <p
                                      style={{
                                        color: "#202223",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        marginBottom: "0px"
                                      }}
                                    >
                                      AS
                                    </p>
                                  </Button>
                                </Col>
                                <Col span={2}>
                                  <div style={{ fontSize: "20px", marginLeft: "-3px"  }}>=</div>
                                </Col>
                                <Col span={18}>
                                  <Input style={{ backgroundColor: "#2F2929", color: "white", width: "90%" }} value={apiOutput} onChange={handleApiOutput} />
                                </Col>
                              </Row>
                            </Card>
                          </TabPane>
                        </Tabs>
                        <Row style={{ float: "right" }}>
                          {editing === true ? <Button style={{ marginLeft : "5px", marginTop : "7px", backgroundColor: "red" }} onClick={handleDelete}><DeleteOutlined style={{ color: "white", fontSize: "15px" }} /></Button> : ""}
                          <Button style={{ marginLeft : editing === true ? "461px" : "515px", marginTop : "7px", backgroundColor: "#6ec25a", color: "white" }} onClick={handleSave}>Save</Button>
                        </Row>
                      </>
                      }
                    </Row>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      }
    </div>
  );
}

export default Functions;
