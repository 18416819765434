import React from 'react'
import {Menu} from 'antd'
import { useHistory } from "react-router-dom";
const { SubMenu } = Menu;


const SideMenu = (props)=> {

  let history = useHistory();
    const NavigateToMenu = (url) =>{
      history.push(`${url}`)
    }

    return (
        <div>
         <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Menu.Item
            key="1"
            style={{ paddingLeft: "15px" }}
            // icon={
            //   <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            // }
            onClick={()=>NavigateToMenu("/")}
          >
            Home
          </Menu.Item>
          <Menu.Item
            key="6"
            style={{ paddingLeft: "15px" }}
            // icon={
            //   <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            // }
            onClick={()=>NavigateToMenu("/applications")}
          >
            Applications
          </Menu.Item>
          <Menu.Item
            key="2"
            style={{ paddingLeft: "15px" }}
            // icon={
            //   <img style={{ cursor: "pointer" }} src={HomeIcon} alt="Home" />
            // }
          >
            Design
          </Menu.Item>
          <SubMenu
            key={"3"}
            style={{ paddingLeft: "-50px" }}
            // icon={<img style={{ cursor: "pointer" }} src={Build} alt="Home" />}
            title={"Build"}
          >
            <Menu.Item
              key="3"
              style={{ paddingLeft: "50px" }}
              onClick={() => NavigateToMenu("/API")}
            >
              API
            </Menu.Item>
          </SubMenu>
          </Menu>
        </div>
    )
}

export default SideMenu