import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tabs, Spin, message, Card, Layout, Input, Select } from "antd";
import "antd/dist/antd.css";
import "../../styles/app.css";
import Inputs from "../Inputs";
import Functions from "../Functions";
import Response from "../Response";
import RunAndDebug from "../../assets/images/runAndDebug.svg";
import Deploy from "../../assets/images/deploy.svg";
import BackNavigate from "../../assets/images/back.svg";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

function Home() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [finalInputs, setFinalInputs] = useState([]);
  const [finalFunctions, setFinalFunctions] = useState([]);
  const [finalResponse, setFinalResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [applicationName, setApplicationName] = useState('');
  const [applicationGroups, setApplicationGroups] = useState([]);
  const [applicationGroupName, setApplicationGroupName] = useState('');
  const [applicationFlag, setApplicationFlag] = useState(false);
  const [newAppName, setNewAppName] = useState('');
  const [newAppDescription, setNewAppDescription] = useState('');
  const [viewApplicationFlag, setViewApplicationFlag] = useState(false);
  const [viewApiGroupFlag, setViewApiGroupFlag] = useState(false);
  const [apiGroup, setApiGroup] = useState([]);
  const [apiGroupFlag, setAPIGroupFlag] = useState(false);
  const [newAPIGroupName, setNewAPIGroupName] = useState('');
  const [newAPIGroupDescription, setNewAPIGroupDescription] = useState('');
  const [apiEndPointData, setApiEndPointData] = useState([]);
  const [handleApiEndpointsFlag, setHandleApiEndpointsFlag] = useState(false);
  const [apiEndPointFlag, setAPIEndPointFlag] = useState(false);
  const [newAPIEndPointName, setNewAPIEndPointName] = useState('');
  const [newAPIEndPointMethod, setNewAPIEndPointMethod] = useState("GET");
  const [route, setRoute] = useState('');
  const [buttonName, setButtonName] = useState('');
  // const [visibleModal, setVisibleModal] = useState(false);
  // const [json, setJson] = useState('');
  function callback(key) {
    // console.log(key);
  }
  const responsiveDesignNew = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };
  const responsiveCol = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 20,
    sm: 12,
    md: 8,
  };

  const responsiveHeader = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 20,
    sm: 12,
    md: 8,
  };

  useEffect(() => {
    if (applications.length === 0) {
      setLoading(true);
      Axios({
        url: 'https://core-services.noton.dev/apiconfig/applications',
        method: 'GET',
        data: null,
      }).then(response => {
        if (response.status === 200) {
          setLoading(false);
          // console.log(response.data.applications);
          setApplications(response.data.applications);
        } else {
          setLoading(false);
        }
      });
    };
  }, []);

  const handleSave = () => {
    setLoading(true);
    // if (finalFunctions.length > 0 && finalResponse.length > 0) {
      let inputArr = []
      finalInputs.map(item => {
        inputArr.push({
          name : item.input
        })
      });
      let functionArr = []
      finalFunctions.map(item => {
        if (item.name === "External API Request") {
          const obj = {};
          for (let i = 0; i < item.headers.length; i++) {
            obj[item.headers[i].type] = item.headers[i].value;
          };
          // const myJSON = JSON.stringify(obj);
          functionArr.push({
            type: "EAPI",
            requestConfig: {
              method : item.method,
              url: item.url,
              headers: obj,
              data: item.params !== undefined ? item.params : "" 
            },
            funcResVar: item.output
          })
        } else if (item.name === "Database Requests") {
          functionArr.push({
            type: "DBR",
            query: item.query,
            funcResVar: item.output,
            dbConfig : item.dbConfig
          })
        } else {
          functionArr.push({
            type: item.name,
            query: item.query,
            funcResVar: ""
          })
        }
      });
      let responseArr = []
      finalResponse.map(item => {
        responseArr.push({
          sourceVar: item.source,
          resposeVar: item.response
        })
      });
      const finalJson = 
      {
        groupName: applicationGroupName,
        groupConfig: {
          endPoints: [
            {
              route: route,
              method: buttonName,
              inputs: inputArr,
              functions: functionArr,
              responses: responseArr
            }
          ]
        },
        applicationName: applicationName
      }
      // setJson(finalJson);
      // setVisibleModal(true);
      Axios({
        url: 'https://core-services.noton.dev/apiconfig/apigroup',
        method: 'POST',
        data: finalJson,
      }).then(response => {
        // console.log(response);
        if (response.status === 200) {
          setLoading(false);
          // console.log(response);
          message.success(response.data.message);
        } else {
          setLoading(false);
          message.error(response.data.message);
        }
      });
    // }
  };

  const addApplications = () => {
    setApplicationFlag(true);
  };

  const handleAppName = (ev) => {
    // console.log(ev.target.value);
    setNewAppName(ev.target.value);
  };

  const handleAppDescription = (ev) => {
    // console.log(ev.target.value);
    setNewAppDescription(ev.target.value);
  };

  const handleNewApp = () => {
    setApplicationFlag(false);
    setLoading(true);
    const result = {
      applicationName : newAppName,
      applicationGroups : [],
      description : newAppDescription
    }
    Axios({
      url: 'https://core-services.noton.dev/apiconfig/applications',
      method: 'POST',
      data: result,
    }).then(response => {
      // console.log(response);
      if (response.status === 200) {
        setLoading(false);
        // console.log(response);
        message.success(response.data.message);
      } else {
        setLoading(false);
        message.error(response.data.message);
      }
    });
  };

  const viewApplication = (groups, name) => () => {
    // console.log(groups);
    setApplicationGroups(groups);
    setApplicationName(name);
    setViewApplicationFlag(true);
    setApplicationFlag(false);
  };

  const addNewApiGroup = () => {
    setAPIGroupFlag(true);
  };

  const handleAPIGroupName = (ev) => {
    // console.log(ev.target.value);
    setNewAPIGroupName(ev.target.value);
  };

  const handleAPIGroupDescription = (ev) => {
    // console.log(ev.target.value);
    setNewAPIGroupDescription(ev.target.value);
  }; 

  const handleNewAPIGroup = () => {
    const data = [...applicationGroups];
    if (newAPIGroupName !== '') {
      data.push(newAPIGroupName);
    };
    setAPIGroupFlag(false);
    setApplicationGroups(data);
  };

  const viewApiGroup = (name) => () => {
    setLoading(true);
    Axios({
      url: `https://core-services.noton.dev/apiconfig/apigroup/${name}`,
      method: 'GET',
      data: null,
    }).then(response => {
      if (response.status === 200) {
        setLoading(false);
        // console.log(response.data.groupConfig.endPoints);
        setApiGroup(response.data.groupConfig.endPoints);
        setApplicationGroupName(name);
        setViewApiGroupFlag(true);
        setViewApplicationFlag(false);
        setApplicationFlag(false);
      } else {
        setLoading(false);
        message.error(response.data.message);
      }
    });
  };

  const addNewApiEndPoint = () => {
    setAPIEndPointFlag(true);
  };

  const handleAPIEndPointName = (ev) => {
    // console.log(ev.target.value);
    setNewAPIEndPointName(ev.target.value);
  };

  const handleAPIEndPointMethod = (ev) => {
    // console.log(ev.target.value);
    setNewAPIEndPointMethod(ev);
  }; 

  const handleNewAPIEndPoint = () => {
    const data = [...apiGroup];
    if (newAPIEndPointName !== '') {
      data.push({
        route: `/${newAPIEndPointName}`,
        method: newAPIEndPointMethod,
        inputs: [],
        functions: [],
        responses: []
      });
    }
    setApiGroup(data);
    setAPIEndPointFlag(false);
  };

  const handleApiEndpoints = (apiData) => () => {
    setRoute(apiData.route);
    setButtonName(apiData.method);
    setApiEndPointData(apiData);
    setHandleApiEndpointsFlag(true);
    setViewApiGroupFlag(false);
    setViewApplicationFlag(false);
    setApplicationFlag(false);
  };

  return (
    <Spin indicator={<LoadingOutlined spin />} spinning={loading} size="large">
      {applicationFlag === true ? 
        <div>
          <Row>
            <Col span={12}>
              <p style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => { setApplicationFlag(false); }}>
                <img
                  style={{
                    cursor: "pointer",
                    marginRight: "1px",
                    width: "10px",
                    marginTop: "-3px",
                    
                  }}
                  src={BackNavigate}
                  alt="Back"
                />
                Back
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", width: "152px", font: "normal normal bold 26px/34px Roboto", marginTop: "-18px"}}>Applications</h2>
            </Col>
          </Row>
          <div>
            <Card style={{ width: "679px", height: "385px", top: "109px", left: "359px", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #00000021", opacity: 1 }}>
              <Row>
                <span style={{
                  font: "normal normal normal 14px/19px Roboto",
                  letterSpacing: "0.28px",
                  color: "#888A8B",
                  opacity: 1,
                  marginTop: "40px",
                  marginLeft: "280px"
                }}>
                  Add Application
                </span>
              </Row>
              <br />
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Name</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <Input style={{ width: "92%" }} onChange={handleAppName} />
              </Row>
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Description</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <TextArea style={{ width: "92%" }} onChange={handleAppDescription} />
              </Row>
              <Row style={{ marginLeft: "466px", marginTop: "40px" }}>
                <Button style={{ backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #1D7B07" }} onClick={() => { setApplicationFlag(false); }}>
                  Cancel
                </Button>
                &nbsp;
                <Button 
                  style={{ backgroundColor: "#228F08", border: "0.5px solid #1D7B07", color: "white" }}
                  onClick={handleNewApp}
                >
                  Save
                </Button>
              </Row>
            </Card>
          </div>
        </div> : 
        apiGroupFlag === true ?
        <div>
          <Row>
            <Col span={12}>
              <p style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => { setAPIGroupFlag(false); }}>
                <img
                  style={{
                    cursor: "pointer",
                    marginRight: "1px",
                    width: "10px",
                    marginTop: "-3px",
                    
                  }}
                  src={BackNavigate}
                  alt="Back"
                />
                Back
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", width: "152px", font: "normal normal bold 26px/34px Roboto", marginTop: "-18px"}}>API Groups</h2>
            </Col>
          </Row>
          <div>
            <Card style={{ width: "679px", height: "385px", top: "109px", left: "359px", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #00000021", opacity: 1 }}>
              <Row>
                <span style={{
                  font: "normal normal normal 14px/19px Roboto",
                  letterSpacing: "0.28px",
                  color: "#888A8B",
                  opacity: 1,
                  marginTop: "40px",
                  marginLeft: "280px"
                }}>
                  Add API Group
                </span>
              </Row>
              <br />
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Name</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <Input style={{ width: "92%" }} onChange={handleAPIGroupName} />
              </Row>
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Description</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <TextArea style={{ width: "92%" }} onChange={handleAPIGroupDescription} />
              </Row>
              <Row style={{ marginLeft: "466px", marginTop: "40px" }}>
                <Button style={{ backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #1D7B07" }} onClick={() => { setAPIGroupFlag(false); }}>
                  Cancel
                </Button>
                &nbsp;
                <Button 
                  style={{ backgroundColor: "#228F08", border: "0.5px solid #1D7B07", color: "white" }}
                  onClick={handleNewAPIGroup}
                >
                  Save
                </Button>
              </Row>
            </Card>
          </div>
        </div> :
        apiEndPointFlag === true ?
        <div>
          <Row>
            <Col span={12}>
              <p style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => { setAPIEndPointFlag(false); }}>
                <img
                  style={{
                    cursor: "pointer",
                    marginRight: "1px",
                    width: "10px",
                    marginTop: "-3px",
                    
                  }}
                  src={BackNavigate}
                  alt="Back"
                />
                Back
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", width: "152px", font: "normal normal bold 26px/34px Roboto", marginTop: "-18px"}}>API Endpoint</h2>
            </Col>
          </Row>
          <div>
            <Card style={{ width: "679px", height: "385px", top: "109px", left: "359px", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #00000021", opacity: 1 }}>
              <Row>
                <span style={{
                  font: "normal normal normal 14px/19px Roboto",
                  letterSpacing: "0.28px",
                  color: "#888A8B",
                  opacity: 1,
                  marginTop: "40px",
                  marginLeft: "280px"
                }}>
                  Add API Endpoint
                </span>
              </Row>
              <br />
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Name</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <Input style={{ width: "92%" }} onChange={handleAPIEndPointName} />
              </Row>
              <br />
              <Row style={{ marginLeft: "40px" }}>
                <span style={{ fontSize: "14px", opacity: 1, font: "normal normal medium 14px/19px Roboto" }}>Method</span>
              </Row>
              <Row style={{ marginLeft: "40px" }}>
                <Select
                  style={{ width: 300 }}
                  onChange={handleAPIEndPointMethod}
                  value={newAPIEndPointMethod}
                >
                  <Option value="GET">GET</Option>
                  <Option value="POST">POST</Option>
                </Select>
              </Row>
              <Row style={{ marginLeft: "466px", marginTop: "40px" }}>
                <Button style={{ backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #1D7B07" }} onClick={() => { setAPIEndPointFlag(false); }}>
                  Cancel
                </Button>
                &nbsp;
                <Button 
                  style={{ backgroundColor: "#228F08", border: "0.5px solid #1D7B07", color: "white" }}
                  onClick={handleNewAPIEndPoint}
                >
                  Save
                </Button>
              </Row>
            </Card>
          </div>
        </div> :
        viewApplicationFlag === true ?
        <div>
          <Row>
            <Col span={12}>
              <p style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => { setApplicationFlag(false); setViewApplicationFlag(false); }}>
                <img
                  style={{
                    cursor: "pointer",
                    marginRight: "1px",
                    width: "10px",
                    marginTop: "-3px",
                    
                  }}
                  src={BackNavigate}
                  alt="Back"
                />
                Back
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", fontSize: "24px", font: "normal normal bold 26px/34px Roboto", marginTop: "-18px"}}>App/{applicationName}</h2>
            </Col>
            <Col span={12}>
              <span style={{ float: "right", marginTop: "-20px" }}>
                <Button style={{ marginBottom: "8px", backgroundColor: "#228F08", color: "white", height: "33px", borderBlockColor: "#1D7B07" }} onClick={addNewApiGroup}>
                  New API Group
                </Button>
              </span>
            </Col>
          </Row>
          <br />
          <span style={{ fontSize: "16px", opacity: 1, font: "normal normal bold 26px/34px Roboto" }}>API Groups</span>
          {applicationGroups.map(group => {
            return (
              <Row>
                <Col span={24}>
                  <Layout style={{ height: "200px", background: "#C7EBBF 0% 0% no-repeat padding-box", border: "1px solid #00000021", opacity: 1 }}>
                    <Sider width={400}>
                      <div style={{ textAlign: "center", opacity: 1, fontSize: "20px", font: "normal normal medium 20px/26px Roboto", marginTop: "80px" }}>{group}</div>
                    </Sider>
                    <Content style={{ backgroundColor: "white" }}>
                      <Row style={{ height: "108px" }}>
                        <Col span={20}>
                          <div style={{ textAlign: "center", opacity: 1, fontSize: "14px", font: "normal normal medium 20px/26px Roboto", marginTop: "60px", marginLeft: "10px", marginRight: "10px" }}>
                            This group contains API endpoints that your front-end will use to communicate with your Database.
                            <br />
                            The default group contains 8 API endpoints for you to start with
                          </div>
                        </Col>
                        <Col span={4} style={{ marginTop: "140px" }}>
                          <Button style={{ backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box", borderBlockColor: "1px solid #00000021" }} onClick={viewApiGroup(group)}>
                            Edit
                          </Button>
                          &nbsp;
                          <Button style={{ backgroundColor: "#FFFFFF 0% 0% no-repeat padding-box", borderBlockColor: "1px solid #00000021" }} onClick={viewApiGroup(group)}>
                            View
                          </Button>
                        </Col>
                      </Row>
                    </Content>
                  </Layout>
                </Col>
              </Row>
            )
          })}
          <br />
        </div> :
        viewApiGroupFlag === true ?
        <div>
          <Row>
            <Col span={12}>
              <p style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => { setViewApplicationFlag(true); setViewApiGroupFlag(false); }}>
                <img
                  style={{
                    cursor: "pointer",
                    marginRight: "1px",
                    width: "10px",
                    marginTop: "-3px",
                    
                  }}
                  src={BackNavigate}
                  alt="Back"
                />
                Back
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", fontSize: "24px", font: "normal normal bold 26px/34px Roboto", marginTop: "-18px"}}>{applicationName}/{applicationGroupName}</h2>
            </Col>
            <Col span={12}>
              <span style={{ float: "right", marginTop: "-20px" }}>
                <Button style={{ marginBottom: "8px", backgroundColor: "#228F08", color: "white", height: "33px", borderBlockColor: "#1D7B07" }} onClick={addNewApiEndPoint}>
                  New API Endpoint
                </Button>
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={14}>
              <div className="addNewApis">
                <Row className="addNewApiHeader">
                  <Col style={{ textAlign: "left" }} {...responsiveHeader}>
                    <p className="fieldHeading">
                      USER
                    </p>
                  </Col>
                </Row>
                {apiGroup.map(api => {
                  return (
                    <Row
                      style={{ borderBottom: "1px solid #DEDEDE", padding: "15px", backgroundColor: "#FFFFFF", cursor: "pointer" }}
                      onClick={handleApiEndpoints(api)}
                    >
                      {/* <p style={{ marginBottom: "0px", cursor: "pointer" }}> */}
                        <Col span={4}>
                          <Button className="apiButton" style={{ backgroundColor: api.method === "GET" ? "#1492E6" : "#29b408", border: api.method === "GET" ? "#117EC6" : "#239b07" }}>{api.method}</Button>
                        </Col>
                        <Col span={20}>
                          <span>{api.route}/</span>
                          <br />
                          <span>Login and retrive an authentication token</span>
                          <br />
                          <span style={{ color: "#707070" }}>{api.inputs.length} inputs, {api.functions.length} funtions, {api.responses.length} responses</span>
                          </Col>
                      {/* </p> */}
                    </Row>
                  )
                })}
              </div>
            </Col>
            &nbsp;
            <Col span={9}>
              <Card style={{ width: "510", height: "207px", backgroundColor: "#F5F5F5 0% 0% no-repeat padding-box", borderBlockColor: "#F5F5F5" }}>
                <span style={{ fontSize: "18px", opacity: 1, font: "normal normal medium 18px/24px Roboto" }}>
                  API Base Group URL
                </span>
                <br />
                <Input />
                <br />
                <br />
                <span style={{ fontSize: "18px", opacity: 1, font: "normal normal medium 18px/24px Roboto" }}>
                  API Endpoints in this Group
                </span>
              </Card>
            </Col>
          </Row>
        </div> :
        handleApiEndpointsFlag === true ? 
        <div>
          <Row>
            <Col {...responsiveCol}>
              <Row>
                <Col {...responsiveDesignNew}>
                  <span>
                    <p style={{ cursor: "pointer" }} onClick={() => { setViewApiGroupFlag(true); setHandleApiEndpointsFlag(false); }}>
                      <img
                        style={{
                          cursor: "pointer",
                          marginRight: "1px",
                          width: "10px",
                          marginTop: "-3px",
                          
                        }}
                        src={BackNavigate}
                        alt="Back"
                      />
                      Back
                    </p>
                  </span>
                </Col>
                <Col {...responsiveDesignNew} style={{ marginTop: "-20px" }}>
                  <p className="apiUrl">{route}/</p>
                  &nbsp;
                  <span>
                    <Button className="apiButton" style={{ backgroundColor: buttonName === "GET" ? "#1492E6" : "#29b408", border: buttonName === "GET" ? "#117EC6" : "#239b07" }}>{buttonName}</Button>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col
              {...responsiveCol}
              style={{ textAlign: "right", marginTop: "20px" }}
            >
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#228f08",
                  cursor: "pointer"
                }}
                onClick={handleSave}
              >
                Save
              </Button>
              &ensp;&nbsp;
              <Button className="runButton">
                <img
                  style={{ cursor: "pointer", marginRight: "1px", width: "23px" }}
                  src={RunAndDebug}
                  alt="Logo"
                />
                Run & Debug
              </Button>
              &ensp;&nbsp;
              <Button className="deployButton">
                <img
                  style={{ cursor: "pointer", width: "15px", marginRight: "3px" }}
                  src={Deploy}
                  alt="Logo"
                />
                Deploy
              </Button>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignNew}>
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Inputs" key="1">
                  <Inputs setSelectedItems={setSelectedItems} setFinalInputs={setFinalInputs} apiEndPointData={apiEndPointData} />{" "}
                </TabPane>
                <TabPane tab="Function Stack" key="2">
                  <Functions selectedItems={selectedItems} setFinalFunctions={setFinalFunctions} apiEndPointData={apiEndPointData} />
                </TabPane>
                <TabPane tab="Response" key="3">
                  <Response selectedItems={selectedItems} setFinalResponse={setFinalResponse} finalFunctions={finalFunctions} apiEndPointData={apiEndPointData} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          {/* <Modal
            visible={visibleModal}
            onCancel={() => setVisibleModal(false)}
            getContainer={false}
            maskClosable={false}
            footer={[
              <Button onClick={() => setVisibleModal(false)}>
                Cancel
              </Button>,
              <Button
                onClick={() => setVisibleModal(false)}
              >
                Save
              </Button>,
            ]}
          >
            {json}
          </Modal> */}
        </div> :
        <div>
        <Row>
          <Col span={12}>
            <h2 style={{ color: "#202223", opacity: 1, letterSpacing: "0.52px", width: "152px", font: "normal normal bold 26px/34px Roboto", marginTop: "14px"}}>Applications</h2>
          </Col>
          <Col span={12}>
          <span style={{ float: "right" }}>
            <Button style={{ marginBottom: "8px", backgroundColor: "#228F08", color: "white", width: "93px", height: "33px", borderBlockColor: "#1D7B07", marginTop: "14px" }} onClick={addApplications}>
              New App
            </Button>
          </span>
          </Col>
        </Row>
        <br />
        <br />
        {applications.map(application => {
          return (
            <Row>
              <Col span={11} style={{ marginLeft: "25px" }}>
                <Layout style={{ height: "108px", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #00000021", opacity: 1 }}>
                  <Sider>
                    <div style={{ textAlign: "center", opacity: 1, fontSize: "20px", font: "normal normal medium 20px/26px Roboto", marginTop: "38px" }}>{application.applicationName}</div>
                  </Sider>
                  <Content>
                    <Row style={{ height: "108px" }}>
                      <Col span={18}>
                        <div style={{ textAlign: "center", opacity: 1, fontSize: "14px", font: "normal normal medium 20px/26px Roboto", marginTop: "30px", marginLeft: "10px", marginRight: "10px" }}>Contains all test cases related to Point of Sales system</div>
                      </Col>
                      <Col span={6} style={{ marginTop: "38px" }}>
                        <Button onClick={viewApplication(application.applicationGroups, application.applicationName)}>
                          View
                        </Button>
                      </Col>
                    </Row>
                  </Content>
                </Layout>
              </Col>
              <Col span={1}>
              </Col>
            </Row>
          )
        })}
      </div>
      }
    </Spin>
  );
}

export default Home;
