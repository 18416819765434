import axios from "axios";
import url from "../config";

const tokenInstance = axios.create();
const getToken = async (username, password) => {
  const getConversionUrl =`${url.Auth}?grant_type=password&username=${username}&password=${password}`
  const token = await tokenInstance({
    method: "post",
    url: getConversionUrl,
    headers: {'Content-Type': "application/x-www-form-urlencoded",'Authorization':'Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=','applicationId':'NOTON'},  
  });
  return token.data;
};

export { getToken };
