module.exports={

    /* DEV Server URL'S 
=================================================== */
//    URL:'https://tests.noton.dev/cw-noton/graphql',
//    Auth:'https://identity-test.mycw.in/cw-identity/oauth/token',


   /* Test Server URL'S 
=================================================== */
// URL:'https://s20q1.mycw.in/cw-noton/graphql',
// Auth:'https://s20q1.mycw.in/cw-identity/oauth/token'


 /* Prepod Server URL'S 
=================================================== */
URL:'https://sapp.noton.dev/cw-noton/graphql',
Auth:'https://s360app.mycw.in/cw-identity/oauth/token',

} 